/** @jsxImportSource @emotion/react */
import React from 'react'

export function FourOhFour() {
  return (
    <div>
      404
    </div>
  )
}
